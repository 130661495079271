<template>
  <v-container>
    <iframe class="text-center" width="600" height="450" src="https://datastudio.google.com/embed/reporting/dffee93d-b6a0-4608-b1d4-e241521d39d6/page/p_cym2mov0xc" frameborder="0" style="border:0" allowfullscreen></iframe>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    loading: true
  }),
  created () {
    this.$store.dispatch('role/getRoles')
  }
}
</script>
<style>
iframe {
width: 100%;
height: 87vh;
}
</style>
